import React from "react"
import { graphql } from "gatsby"

import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"
import ArticleCard from "../components/ArticleCard/ArticleCard"
import Seo from "../components/Seo"

const News = ({ data: { allWpNew } }) => {
  const allNews = allWpNew.nodes
  return (
    <React.Fragment>
      <Seo title={"News"} />
      <Navbar />
      <main className="marginTopNavbar">
        <div className="main-container">
          <section className="latest-news">
            <h1 className="latest-news__title">News</h1>
            <div className="latest-news__grid">
              {allNews.map(news => {
                const title = news.NewGQL.title
                const image =
                  news.NewGQL.thumbnailImage.localFile.childImageSharp
                    .gatsbyImageData
                const date = news.date
                const uri = news.uri

                return (
                  <ArticleCard
                    key={uri}
                    title={title}
                    alt={title}
                    image={image}
                    date={date}
                    uri={uri}
                  />
                )
              })}
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export const news = graphql`
  {
    allWpNew(sort: { fields: date, order: DESC }) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        NewGQL {
          title
          thumbnailImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.46)
              }
            }
          }
        }
      }
    }
  }
`
export default News
